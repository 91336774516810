import type { ProjectSchema } from '@readme/backend/models/project/types';
import type { BareFetcher, Key, SWRConfiguration } from 'swr';

import { useCallback, useContext } from 'react';
import useSWR from 'swr';

import type { ConfigContextValue } from '@core/context';
import { ConfigContext, ProjectContext } from '@core/context';
import useTimezone from '@core/hooks/useTimezone';

type Entries<T> = {
  [K in keyof T]: [K, T[K]];
}[keyof T][];

interface MetricsHeaders extends Headers {
  entries: () => Entries<{ name: string; value: string }>;
}

interface MetricsError extends Error {
  info?: Response | unknown;
  status: number;
}

interface FetcherResponse<T> {
  headers: Record<string, string>;
  response: T;
}

export interface FetcherOptions {
  apiKey: string;
  body?: object;
  headers?: Record<string, string>;
  host: string;
  method: string;
  path: string;
}

async function fetcher<T>({
  apiKey,
  body,
  headers,
  host,
  method = 'GET',
  path,
}: FetcherOptions): Promise<FetcherResponse<T>> {
  const res = await fetch(`${host}/v3/${path}`, {
    headers: {
      authorization: `Basic ${Buffer.from(`${apiKey}:`).toString('base64')}`,
      'cache-control': 'no-cache',
      'content-type': body ? 'application/json' : 'text/plain',
      pragma: 'no-cache',
      ...headers,
    },
    mode: 'cors',
    method,
    body: body ? JSON.stringify(body) : undefined,
  });

  const response = await res.json();

  /**
   * Handle error responses.
   */
  if (!res.ok) {
    const error = new Error("Couldn't fetch metrics data.") as MetricsError;
    error.info = response;
    error.info = error?.info || {};
    error.status = res.status;
    throw error;
  }

  return {
    response,
    headers: Object.fromEntries((res.headers as MetricsHeaders).entries()),
  };
}

export function useMetricsAPIFetcher<T>() {
  const {
    metrics: { dashUrl = '' },
  } = useContext(ConfigContext) as ConfigContextValue;
  const { project } = useContext(ProjectContext);

  // eslint-disable-next-line readme-internal/no-legacy-project-api-keys
  const { apiKey } = project as ProjectSchema;

  const fetchCallback = useCallback(
    async ({ path, method = 'GET', body }: { body?: object; method?: string; path: string }) => {
      const { response } = await fetcher<T>({
        host: dashUrl,
        path,
        apiKey,
        body,
        method,
      });

      return response;
    },
    [dashUrl, apiKey],
  );

  return fetchCallback;
}

/**
 * Base Metrics API fetcher hook
 */
export function useMetricsAPI<Data>(path: string, isReady = true, fetcherOptions: Partial<FetcherOptions> = {}) {
  const {
    metrics: { dashUrl = '' },
  } = useContext(ConfigContext) as ConfigContextValue;
  const { project } = useContext(ProjectContext);
  let timezone = useTimezone();

  let isUTCFallback = false;

  // If timezone doesn't resolve for some reason, we don't want requests to fail
  // We'll add a custom fallback to UTC in this scenario
  if (!timezone) {
    timezone = 'UTC';
    isUTCFallback = true;
  }

  // eslint-disable-next-line readme-internal/no-legacy-project-api-keys
  const { apiKey } = project as ProjectSchema;
  const swrOptions: SWRConfiguration = {
    revalidateOnMount: true,
    revalidateOnFocus: false,
    shouldRetryOnError: false,
  };

  const config: Key = {
    host: dashUrl,
    path,
    apiKey,
    ...fetcherOptions,
    // Always include x-timezone in headers for all Metrics requests
    headers: {
      ...fetcherOptions.headers,
      'x-timezone': timezone,
    },
  };

  const isReadyToFetch = isReady && (timezone !== 'UTC' || isUTCFallback);

  /**
   * We cast the fetcher to BareFetcher<Data> here because we're using a generic type
   * and returning a type that includes the response headers
   * */
  const { data, error, isValidating } = useSWR<Data>(
    isReadyToFetch ? config : null,
    fetcher as BareFetcher<Data>,
    swrOptions,
  );
  const { response, headers } = (data || {}) as FetcherResponse<Data>;

  return {
    data: response,
    headers,
    error,
    isLoading: !error && !response,
    isValidating,
  };
}

export default useMetricsAPI;
