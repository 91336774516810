import type { $TSFixMe } from '@readme/iso';

import * as amplitude from '@amplitude/analytics-browser';
import { AMPLITUDE_EVENT, AMPLITUDE_EVENT_PROPERTY } from '@readme/iso/src/amplitude';
import { useCallback, useContext, useEffect, useMemo } from 'react';

import { ProjectContext, UserContext, ConfigContext } from '@core/context';
import useUserPermissions from '@core/hooks/useUserPermissions';

type EventProperties = Record<string, $TSFixMe>;

export default function useAmplitude({ configOpts }: { configOpts?: amplitude.Types.DefaultTrackingOptions } = {}) {
  const { project } = useContext(ProjectContext) as { project: $TSFixMe };
  const user = useContext(UserContext) as $TSFixMe;
  const { releaseVersion, amplitude: amplitudePublicKey } = useContext(ConfigContext) as $TSFixMe;

  const { isAdminUser } = useUserPermissions();

  // Initialize Amplitude's client via our environment public key, the current auth'd user, and any other options
  // DefaultTracking is disabled, because we're not concerned with tracking all page views and other ancillary data
  // This also keeps our usage lower and more explicit
  useEffect(() => {
    amplitude.init(amplitudePublicKey, user?.email, { defaultTracking: false, ...configOpts });
  }, [user?.email, amplitudePublicKey, configOpts]);

  // Give additional context to current Amplitude client
  // We're currently only tracking project plan + subdomain, but this can easily be ammended
  useEffect(() => {
    const { subdomain, plan } = project;

    amplitude.setGroup('plan', plan);
    amplitude.setGroup('subdomain', subdomain);
  }, [project]);

  // Core data included with every tracking event
  const eventMetaData = useMemo(() => {
    const { metrics } = project;

    return {
      ...(metrics && { metrics: { monthlyPurchaseLimit: metrics.monthlyPurchaseLimit } }),
      permission: isAdminUser ? 'admin' : 'readonly',
      releaseVersion,
    };
  }, [project, releaseVersion, isAdminUser]);

  // `amplitude.track` wrapper -> consume our core data, along with anything else passed in
  const track = useCallback(
    (event: AMPLITUDE_EVENT, eventProperties?: EventProperties) => {
      return amplitude.track(event, { ...eventMetaData, ...eventProperties });
    },
    [eventMetaData],
  );

  // Set user ID for Amplitude client (i.e. in signup flows as it becomes known)
  const setUserID = useCallback((userID: string) => {
    amplitude.setUserId(userID);
  }, []);

  // Expose core methods
  return { setUserID, track };
}

// Export event enums here as well for easy access when importing hook
export { AMPLITUDE_EVENT, AMPLITUDE_EVENT_PROPERTY };
