/** ***** BUSINESS LOGIC for Developer Dashboard Billing ******* */
export const METRICS_BASE = {
  COST: 100,
  LOGS: 5e6,
};
export const METRICS_OVERAGE = {
  COST: 10,
  LOGS: 1e6,
};
export const METRICS_CAP = {
  COST: 1000,
  LOGS: 95e6,
};
/** **************************** */
