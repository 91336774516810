import loadable from '@loadable/component';
import PropTypes from 'prop-types';
import React, { useCallback, useContext } from 'react';
import { ReactTitle } from 'react-meta-tags';
import { Redirect, Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';

import AppTheme from '@Hub/Theme';

import AppContext, { UserContext } from '@core/context';
import useAmplitude from '@core/hooks/useAmplitude';
import useEnvInfo from '@core/hooks/useEnvInfo';
import useEventListener from '@core/hooks/useEventListener';

import { MetricsProviderWithUsage } from '@routes/Dash/Project/Metrics/Context';

import { NotificationToaster } from '@ui/Notification';

import { useExposeHistory } from './history';
import './root.scss';

// SSR routes
const AdminRouter = loadable(() => import('@routes/Dash/Admin'));
const OnboardingRouter = loadable(() => import('@routes/Dash/Onboarding'));
const Homepage = loadable(() => import('@routes/Dash/Homepage'));

// Non-SSR routes
const APIAccessTokens = loadable(() => import('@routes/Dash/Project/Settings/APIAccessTokens'), { ssr: false });
const DashOverview = loadable(() => import('@routes/Dash/Project/Overview'), { ssr: false });
const DocsEditorRoutes = loadable(() => import('@routes/Dash/Project/DocsEditor/Routes'), { ssr: false });
const HubSignIn = loadable(() => import('@routes/Dash/HubSignIn'), { ssr: false });
const Metrics = loadable(() => import('@routes/Dash/Project/Metrics'), { ssr: false });
const MyDevelopers = loadable(() => import('@routes/Dash/Project/MyDevelopers'), { ssr: false });
const PersonalizedDocs = loadable(() => import('@routes/Dash/Project/Settings/PersonalizedDocs'), { ssr: false });
const Quickstart = loadable(() => import('@routes/Dash/Project/Quickstart'), { ssr: false });
const SetupAPIKeys = loadable(() => import('@routes/Dash/Project/Setup/APIKeys'), { ssr: false });
const SetupAPILogs = loadable(() => import('@routes/Dash/Project/Setup/APILogs'), { ssr: false });

const Labs = loadable(() => import('@routes/Dash/Project/Labs'), { ssr: false });

const EntEndUsers = loadable(() => import('@routes/Dash/Enterprise/EndUsers'), { ssr: false });
const EntEndUsersMgmt = loadable(() => import('@routes/Dash/Enterprise/EndUsersMgmt'), { ssr: false });
const EntProjects = loadable(() => import('@routes/Dash/Enterprise/ProjectManagement'), { ssr: false });
const EntTeammates = loadable(() => import('@routes/Dash/Enterprise/Teammates'), { ssr: false });
const EntStaging = loadable(() => import('@routes/Dash/Enterprise/Staging'), { ssr: false });

const ChatGPTAdmin = loadable(() => import('@routes/Dash/ChatGPT'), { ssr: false });

const CustomBlocksMgmt = loadable(() => import('@routes/Dash/CustomBlocks/Management'), { ssr: false });
const ManagePlans = loadable(() => import('@routes/Dash/ManagePlans'), { ssr: false });

const EnterpriseDashRouter = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}/projects`}>
        <EntProjects />
        <div className="ModalWrapper" id="manage-projects-modal" />
      </Route>
      <Route path={`${path}/team`}>
        <EntTeammates />
      </Route>
      <Route path={`${path}/end-users/login`}>
        <EntEndUsers />
      </Route>
      <Route path={`${path}/end-users/management`}>
        <EntEndUsersMgmt />
      </Route>
      <Route path={`${path}/staging`}>
        <EntStaging />
      </Route>
      <Route path={`${path}/personalized-docs`}>
        <PersonalizedDocs />
      </Route>
      <Route path={`${path}/owlbot`}>
        <ChatGPTAdmin onEnterpriseDash />
      </Route>
      <Route path={`${path}/reusable-content`}>
        <CustomBlocksMgmt />
      </Route>
      <Route path={`${path}/custom-components`}>
        <CustomBlocksMgmt type="component" />
      </Route>
    </Switch>
  );
};

const ProjectDashRouter = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}/quickstart`}>
        <Quickstart />
      </Route>
      <Route path={`${path}/overview`}>
        <MetricsProviderWithUsage>
          <DashOverview />
        </MetricsProviderWithUsage>
      </Route>
      {/* MyDevelopers initial view and optional segments or API key insights subroutes */}
      <Route path={`${path}/metrics/developers/:type(segment|key)?/:slugOrKey?`}>
        <MetricsProviderWithUsage>
          <MyDevelopers />
        </MetricsProviderWithUsage>
      </Route>
      <Route path={`${path}/metrics/setup-api-keys`}>
        <SetupAPIKeys />
      </Route>
      <Route path={`${path}/metrics/setup-api-logs`}>
        <SetupAPILogs />
      </Route>
      <Route path={`${path}/metrics`}>
        <MetricsProviderWithUsage>
          <Metrics />
        </MetricsProviderWithUsage>
      </Route>
      <Route path={`${path}/api-key`}>
        <APIAccessTokens />
      </Route>
      <Route path={`${path}/owlbot`}>
        <ChatGPTAdmin />
      </Route>
      <Route path={`${path}/personalized-docs`}>
        <PersonalizedDocs />
      </Route>
      <Route path={`${path}/(changelog|docs|intro|page|reference|refs)`}>
        <DocsEditorRoutes path={path} />
      </Route>
      <Route path={`${path}/reusable-content`}>
        <CustomBlocksMgmt type="content" />
      </Route>
      <Route path={`${path}/custom-components`}>
        <CustomBlocksMgmt type="component" />
      </Route>
      <Route path={`${path}/labs`}>
        <Labs theme="light" />
      </Route>
      <Route path={`${path}/plans`}>
        <ManagePlans />
      </Route>
    </Switch>
  );
};

const Dash = props => {
  useExposeHistory(props.baseUrl);
  const { pathname, ...location } = useLocation();
  const { isClient } = useEnvInfo();

  const { is_god: isGod } = useContext(UserContext);

  const { context, isOnboardingRoute, showRefreshedHome } = props;
  const { disableSignups } = context.project.flags || {};

  // Our quickswitcher is currently implemented in ng code, but tracking amplitude events in react given we don't initialize amplitude in ng
  // This will not track events at the root dash index page as react isn't loaded there and instead will only capture events in dash projects
  const { track } = useAmplitude();
  const handleToggleQuickSwitcher = useCallback(
    e => {
      const isTogglingQuickSwitcher = e.key === 'k' && (e.metaKey || e.ctrlKey);
      const isQuickSwitcherAlreadyOpen = !!document.getElementById('quickswitcher');
      if (!isTogglingQuickSwitcher || isQuickSwitcherAlreadyOpen) return;

      track('Dash Search Modal');
    },
    [track],
  );
  useEventListener('keydown', handleToggleQuickSwitcher);

  /**
   * @important  Make sure these routes are only ever visible to super admins!
   * @note       Admin routes don't have a project context, and will throw an
   *             error if they're wrapped in our custom theme logic. In order
   *             to address this issue, we need to render them separately.
   */
  if (isGod && pathname.startsWith('/admin')) return <AdminRouter />;

  /**
   * @important  Onboarding routes are only ever visible to non-logged in users
   *             and should sit aside from the rest of the Dash app routing.
   * @note       These routes don't have a project context, and will throw an
   *             error if they're wrapped in our custom theme logic. In order
   *             to address this issue, we need to render them separately.
   * @note       These routes must also be specified in the ONBOARDING_ROUTES
   *             array in packages/react-interop/middleware/react.js
   */
  if (isOnboardingRoute) return <OnboardingRouter />;

  /**
   * Refreshed homepage view should only be shown when feature flag is enabled
   * and the user is on the root route.
   *
   * Note: We need to check both the pathname and the window.location.pathname
   * because the former is used in the server-side rendering and the latter is
   * used in the client-side rendering.
   */
  const showRefreshedHomepage =
    showRefreshedHome &&
    ((pathname === '/' && props.location === '/') || (isClient && window.location.pathname === '/'));

  return (
    <AppTheme>
      <Switch>
        <Route exact path="/">
          {!!showRefreshedHomepage && <Homepage {...props} />}
        </Route>
        <Route path="/to/:subdomain/signup">
          {disableSignups ? (
            <Redirect to={{ pathname: pathname.replace(/\/signup$/, ''), ...location }} />
          ) : (
            <HubSignIn signingUp />
          )}
        </Route>
        <Route path="/to/:subdomain">
          <HubSignIn signingUp={false} />
        </Route>
        <Route path="/group/:subdomain">
          <EnterpriseDashRouter />
        </Route>
        <Route path="/project/:subdomain/:version">
          <ProjectDashRouter />
        </Route>
        <Route path="/user/edit">
          <ReactTitle title="Edit profile | ReadMe" />
        </Route>
        <Route path="/user/invoices">
          <ReactTitle title="Invoices | ReadMe" />
        </Route>

        <Route path="/">{/* FALLBACK */}</Route>
      </Switch>
    </AppTheme>
  );
};

// These props are set by DashReact middleware
Dash.propTypes = {
  apiBaseUrl: PropTypes.string,
  baseUrl: PropTypes.string,
  config: PropTypes.object,
  context: PropTypes.shape({
    project: PropTypes.object,
    terms: PropTypes.arrayOf(PropTypes.object),
    user: PropTypes.object,
    variables: PropTypes.object,
    version: PropTypes.object,
  }),
  /** Whether this is an onboarding route */
  isOnboardingRoute: PropTypes.bool,
};

export default function DashApp({ config = {}, ...props }) {
  return (
    <AppContext config={config} {...props}>
      <Dash {...props} />
      <div className="ModalWrapper" id="modal-target" />
      {/* Top-level Toaster for displaying notifications in Dash */}
      <NotificationToaster />
    </AppContext>
  );
}

DashApp.propTypes = Dash.propTypes;
